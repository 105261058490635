import type { ReactElement, ReactNode } from "react";
import { ComboboxOptions, ComboboxOption } from "@headlessui/react";
import { Icon, Inline, Text, ScrollArea } from "~/components/vendorUI";
import { useDropdownSwitcher } from "../context/DropdownSwitcherContext";
import { GroupTitle } from "./GroupTitle";
import { css } from "ui/css";
import { useTranslation } from "~/utils";

const comboboxOptionCSS = css({
  "--paddingBlock": "token(spacing.8px)",
  padding: "var(--paddingBlock) 16px",
  cursor: "pointer",
  justifyContent: "flex-start",

  '&[data-headlessui-state~="active"]': {
    backgroundColor: "bg.formControlOptionActive",
    color: "black",
  },
  '&[data-headlessui-state~="selected"]': {
    fontWeight: "medium",
    padding: "var(--paddingBlock) 12px var(--paddingBlock) token(spacing.16px)",
  },
  '&[data-headlessui-state~="disabled"]': {
    cursor: "not-allowed",
    color: "steel",
  },
});

const optionOverflowCSS = css({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "248px",
  paddingBlock: "8px",
});

const iconCSS = css({ alignSelf: "flex-start" });
const noOptionsCSS = css({ padding: "16px" });

const comboboxPopoverCSS = css({
  "& [data-radix-scroll-area-viewport]": {
    background: `linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
     linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
     linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) center top,
     linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) center bottom`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 20px, 100% 20px, 100% 8px, 100% 8px",
    backgroundAttachment: "local, local, scroll, scroll",
    paddingBlock: "8px",
  },
});

type ListRenderProps<T> = ({
  item,
  index,
}: {
  item: T;
  index: number;
}) => ReactElement;

type ListProps<T> = {
  children?: ReactElement | ListRenderProps<T>;
};

const OptionsWrapper = <T,>({
  children,
  ...props
}: Omit<ListProps<T>, "children"> & { children: ReactNode }) => (
  <ScrollArea maxHeight={260} className={comboboxPopoverCSS}>
    <ComboboxOptions static {...props}>
      {children}
    </ComboboxOptions>
  </ScrollArea>
);

export const List = <T extends object>({
  children,
  ...props
}: ListProps<T>): ReactElement => {
  const { t } = useTranslation();
  const {
    filteredList,
    getUniqueValue,
    getLabelValue,
    groupedFilteredList,
    selectedOptionRef,
    disabled,
  } = useDropdownSwitcher();

  if (children) {
    return typeof children === "function" ? (
      <OptionsWrapper {...props}>
        {filteredList.map((item: T, index: number) =>
          children({ item, index }),
        )}
      </OptionsWrapper>
    ) : (
      children
    );
  }

  if (!filteredList.length) {
    return (
      <OptionsWrapper {...props}>
        <Text color="secondary" className={noOptionsCSS}>
          {t("DROPDOWN-SWITCHER_NO-RESULTS-FOUND")}
        </Text>
      </OptionsWrapper>
    );
  }

  const showListItems = (itemList: T[]) =>
    itemList.map((item: T) => (
      <Inline
        align="center"
        gap="16px"
        distribute="between"
        key={getUniqueValue(item)}
        asChild
      >
        <ComboboxOption
          disabled={disabled}
          value={item}
          className={comboboxOptionCSS}
        >
          {({ selected }) => (
            <>
              <Text
                ref={selected ? selectedOptionRef : undefined}
                size="14px"
                className={optionOverflowCSS}
                asChild
              >
                <div title={getLabelValue(item)}>{getLabelValue(item)}</div>
              </Text>
              {selected ? (
                <Icon size="24px" name="Check" className={iconCSS} />
              ) : (
                <div className={css({ padding: "12px" })} />
              )}
            </>
          )}
        </ComboboxOption>
      </Inline>
    ));

  const groupedListItems = Object.keys(groupedFilteredList).map(
    (groupTitle: string, i: number) => (
      <div key={i}>
        {groupTitle !== "undefined" && <GroupTitle>{groupTitle}</GroupTitle>}
        {showListItems(groupedFilteredList[groupTitle])}
      </div>
    ),
  );

  return (
    <OptionsWrapper {...props}>
      {Object.keys(groupedFilteredList).length > 0
        ? groupedListItems
        : showListItems(filteredList)}
    </OptionsWrapper>
  );
};
